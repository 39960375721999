import React from 'react'
import { DigitalMediaView } from '@views'

import { graphql } from 'gatsby'
import { Seo } from '@atoms'

export const Head = () => {
  return <Seo title={'Digital Media'} />
}

const DigitalMedia = (props) => {
  const data = props?.data?.contentfulCaseStudyGrid

  return <DigitalMediaView data={data} />
}

export const digitalQuery = graphql`
  query digitalQuery {
    contentfulCaseStudyGrid(page: { eq: "Digital Media" }) {
      ...CaseStudyGrid
    }
  }
`

export default DigitalMedia
